.container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: fit-content;
    background-color: #064420;
    color: #faf1e6;
    font-size: 1em;
    margin-top: auto;
}

.container img {
    margin-top: .5em;
    height: 3em;
    margin-left: 1em;
}

