.container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #064420;
  }

  .logo_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
  }
  
  .logo_title p {
    font-family: "Architects_Daughter", cursive;
    color: #faf1e6;
    letter-spacing: 0.1em;
    text-align: center;
    margin: .7em 0 0 0 ;
    font-size: .8em;
    text-transform: capitalize;
  
  }
  
  .logo_title img {
    margin: 0;
    height: 100%;
    height: 4em;
  }