.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #064420;
}

.main_nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  font-size: 1em;
  margin: 0;
  padding: 0.1em;
}


.container a:hover {
  background-color: #0c6431;
  cursor: pointer;

}

.container ul li a {
  display: block;
  font-size: 1em;
  background-color: #064420;
  color: #faf1e6;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Lato;
  font-weight: bold;
  padding: 1em 3em 1em 3em;
  border: none;
}

.container p {
  color: #faf1e6;
  
}


/* Media queries */

@media only screen and (max-width: 1000px) {
  .main_nav {
    display: none;
    font-size: .5em;
    padding: 0;
  }
  
  .main_nav:last-child li:first-child {
    display: none;
  }

  .main_nav:last-child li:last-child {
    display: none;
  }

  

  .logo_title p {
    font-size: .6em;
  }

  .container ul li a {
    padding: 1em 1em 1em 1em;
  }

}

@media only screen and (max-width: 1210px) {
 
  
  .main_nav:last-child li:first-child {
    display: none;
  }

  .main_nav:last-child li:last-child {
    display: none;
  }

}
