.container {
  display: flex;
  justify-content: center;
  width: fit-content;
  border-radius: 15px;
  background-color: #fefefee7;
  margin: 1em 0 1em 0;
  padding: 1em 0 1em 0;
  overflow-x: auto;
  

  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
