.container {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 1em;
  font-family: Arial, Helvetica, sans-serif;
}

.container hr {
  height: 1px;
  width: 90%;
  background-color: black;
  border: none;
}

.container h3 {
  font-size: 1.6em;
}

.container a {
  font-size: 1.6em;
  font-weight: bold;
  margin: 1em;
}

.container a:hover {
  cursor: pointer;
}

#title {
  font-size: 1.6em;
  font-family: Bodoni;
}

#email {
  margin-top: 1.5em;
  text-align: center;
  font-size: clamp(.7rem, 1.5vw, 1.2rem);
  word-break: break-all;
}

iframe {
  width: 95%;
  border-radius: 5px;
  border: 0;
  margin-top: 1em;
  border: 1px solid grey;
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 97vw;
  }
}
.img {
  height: 3.5em;
  margin-top: 2em;
  margin-bottom: -0.8em;
}

.socialMedia img {
  padding: 1em;
  height: 3.5em;
}

.socialMedia img:hover {
  cursor: pointer;
}
