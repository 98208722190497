.wrapper {
  border-radius: 10px;
  align-items: center;
  padding: 1em 0 1em 0;
  background: rgba(211, 211, 211, 0.449);
  font-family: Lato;
  height: 100%;
}

.wrapper h2 {
  font-size: 2rem;
  margin: 0;
}

.wrapper h3 {
  font-size: 1.3rem;
  margin: 0.8em 0 0.8em 0;
}

.wrapper button {
  background-color: #064420;
  color: #faf1e6;
  font-size: 1rem;
  font-weight: bolder;
  margin: 0.3em 0 0.3em 0;
  padding: 0.5em 1.3em 0.5em 1.3em;
  border-radius: 5px;
  border: none;
  transition: all .5s ease;
}

.wrapper button:hover {
  background-color: #0b813c;
  transform: scale(1.2);
}

.imageWrapper img {
  width: 15rem;
  border-radius: 50%;
}

.info {
  width: 85%;
  white-space: pre-line;
  margin: 1em auto;
  font-size: 1.3rem;
}
