@font-face {
  font-family: "Architects_Daughter";
  src: local("Architects_Daughter"),
    url("./fonts/Architects_Daughter/ArchitectsDaughter-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url("./fonts/Raleway/Raleway-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Bodoni";
  src: local("Bodoni"),
    url("./fonts/Bodoni/Bodoni\ Sans\ Text\ Regular.otf") format("truetype");
}

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100%;
  height: fit-content;
}

#root {
  display: flex;
}

a,
a:visited {
  color: black;
  text-decoration: none;
}

.main-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  min-height: 100vh;
  background-color: #fdfaf6;
  background-image: url(./images/studio.jpg);
  background-size: cover;
  background-position: 50% 80%;
}

.row,
.column {
  display: flex;
}

.column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

h1 {
  margin: 0 auto;
  font-family: Lato;
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin-bottom: 1em;
}

.section-header {
  text-transform: uppercase;
  font-size: 2rem;
}

.section-header::after {
  content: "";
  content: "";
  display: block;
  border-bottom: 4px solid black;
  position: absolute;
  bottom: -5px;
  width: 70%;
}
