.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fed8cfb6;
    border:  3px solid black;
    border-radius: 10px;
    width:fit-content;
    height: fit-content;
    margin-top: 1em;
}