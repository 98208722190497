.container {
  border-radius: 10px;
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  padding: 1em;
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 92vw;
  }
}

@media only screen and (min-width: 1000px) {
  .container h3 {
    width: 70%;
  }
}

.container img {
  width: 50%;
  border-radius: 10%;
}


.container h2 {
  width: 80%;
}

