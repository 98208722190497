#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: hidden;
}

/* Individual item */
.bm-item {
  outline: none;
  text-decoration: none;
  margin-bottom: 10px;
  padding-left: 0.5em;
  background-color: #064420;
  color: #d1d1d1;
  transition: color 0.2s;
  list-style: none;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  left: 1.5em;
  top: 1em;
  width: 3em;
  height: 2.5em;
  /* left: 36px;
  top: 36px; */
}
@media only screen and (min-width: 1000px) {
  .bm-burger-button {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #faf1e6;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  /* height: 24px;
  width: 24px; */
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  /* margin-left: auto; */
  background: #faf1e6;
}

/* General sidebar styles */
.bm-menu {
  background-color: #064420;
  font-size: 1.15em;
  overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #064420;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}
.bm-item-list li {
  font-family: sans-serif;
  margin-top: 1em;
  background-color: #064420;
  color: #faf1e6;
  font-size: 1.5em;
  border: none;
}

.bm-item-list li a {
  color: #faf1e6;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
