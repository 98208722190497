.container {
  width: 96vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lato;
}

.container h1 {
  margin: 0.5em 0 0.5em 0;
}

.container h3 {
  text-align: center;
  width: 95%;
  margin: 0;
}

.step1,
.step2,
.info {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  overflow: hidden;
}

.step2 {
  width: 98%;
  margin-top: 1em;
  background-color: #fed8cfb6;
  border: 3px solid black;
  border-radius: 10px;
}

@media only screen and (min-width: 1350px) {
  .step2{
    width: 60%;
  }
}

.step1 h1,
.step2 h1,
.info h1 {
  text-decoration: underline;
  text-align: center;
}
.starterPack,
.privateSessions,
.duetSessions,
.groupClasses {
  width: 98%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 10px;
  margin: 1em 0 0 0;
}

.starterPackCardWrapper,
.privateSessionsWrapper,
.duetSessionsWrapper,
.groupClassesWrapper {
  display: flex;
  overflow: auto;
}

@media only screen and (min-width: 830px) {
  .starterPackCardWrapper,
  .privateSessionsWrapper,
  .duetSessionsWrapper,
  .groupClassesWrapper {
    justify-content: center;
  }
}

.packDesc {
  align-self: center;
  width: fit-content;
  height: fit-content;
  margin-bottom: 0;
}

.scrollRightImg {
  width: fit-content;
  height: fit-content;
  align-self: center;
  margin: 0 -1.6em 0 -1.6em;
}

.scrollRightImg img {
  width: 3em;
  opacity: 0.5;
}

@media only screen and (min-width: 550px) {
  .scrollRightImg img {
    opacity: 0;
  }
}
