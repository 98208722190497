.container {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.greeter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  width: 97vw;
  text-align: center;
  padding: 1em;
  border-radius: 5px;
  color: #012730;
}

.greeter h1 {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-size: 3em;
  font-family: "Raleway", sans-serif;
  animation: myAnim 1s ease 0s 1 normal forwards;
}

.greeter p {
  font-family: "Raleway", sans-serif;
  width: 60%;
  margin: 1em 0 0 0;
  padding: 0;
  font-size: 1.7em;
  font-weight: bold;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 1s;
}

.greeter h4 {
  width: 60%;
  font-size: 1.5em;
  font-family: "Raleway", sans-serif;
}

.greeter a {
  width: 100%;
}

.greeter button {
  text-decoration: none;
  border: none;
  padding: 0.7em 2em 0.7em 2em;
  margin: 1.5em 0 0em 0em;
  border-radius: 5px;
  font-size: 1.5em;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  background-color: #064420;
  color: #faf1e6;
  -webkit-box-shadow: 5px 6px 3px -2px #000000;
  box-shadow: 5px 6px 3px -2px #000000;

  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 2s;
}

.greeter button:hover {
  background-color: #0c6431;
  transform: scale(1.5);
}

.greeter button:active {
  font-size: 1.4em;
}

/* Media Queries */

@media only screen and (max-width: 800px) {
  .greeter {
    margin-top: 2em;
    padding: 0;
  }

  .greeter h1 {
    font-size: 2em;
  }

  .greeter p {
    font-size: 1.3em;
    width: 80%;
  }

  .greeter button {
    padding: 0.5em 1.5em 0.5em 1.5em;
    font-size: 1em;
    width: 50%;
  }

  .greeter button:active {
    font-size: 0.9em;
  }
}

/* Animations */

@keyframes myAnim {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
